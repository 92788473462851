export const imports = {
  'src/docs/home/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-home-index" */ 'src/docs/home/index.mdx'
    ),
  'src/docs/documentation/config/apps.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-apps" */ 'src/docs/documentation/config/apps.mdx'
    ),
  'src/docs/documentation/config/assignments.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-assignments" */ 'src/docs/documentation/config/assignments.mdx'
    ),
  'src/docs/documentation/config/components.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-components" */ 'src/docs/documentation/config/components.mdx'
    ),
  'src/docs/documentation/config/layouts.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-layouts" */ 'src/docs/documentation/config/layouts.mdx'
    ),
  'src/docs/documentation/config/pages.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-pages" */ 'src/docs/documentation/config/pages.mdx'
    ),
  'src/docs/documentation/config/propsmapper.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-propsmapper" */ 'src/docs/documentation/config/propsmapper.mdx'
    ),
  'src/docs/documentation/config/quick-start.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-quick-start" */ 'src/docs/documentation/config/quick-start.mdx'
    ),
  'src/docs/documentation/config/themes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-config-themes" */ 'src/docs/documentation/config/themes.mdx'
    ),
  'src/docs/documentation/data/data.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-data-data" */ 'src/docs/documentation/data/data.mdx'
    ),
  'src/docs/documentation/data/datastore.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-data-datastore" */ 'src/docs/documentation/data/datastore.mdx'
    ),
  'src/docs/documentation/data/intro.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-data-intro" */ 'src/docs/documentation/data/intro.mdx'
    ),
  'src/docs/documentation/data/transformation.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-data-transformation" */ 'src/docs/documentation/data/transformation.mdx'
    ),
  'src/docs/documentation/datasources-integration/docusign.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-docusign" */ 'src/docs/documentation/datasources-integration/docusign.mdx'
    ),
  'src/docs/documentation/datasources-integration/ds_getting_started.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-ds-getting-started" */ 'src/docs/documentation/datasources-integration/ds_getting_started.mdx'
    ),
  'src/docs/documentation/datasources-integration/dynamo_db.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-dynamo-db" */ 'src/docs/documentation/datasources-integration/dynamo_db.mdx'
    ),
  'src/docs/documentation/datasources-integration/epicor.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-epicor" */ 'src/docs/documentation/datasources-integration/epicor.mdx'
    ),
  'src/docs/documentation/datasources-integration/function.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-function" */ 'src/docs/documentation/datasources-integration/function.mdx'
    ),
  'src/docs/documentation/datasources-integration/googleSheet.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-google-sheet" */ 'src/docs/documentation/datasources-integration/googleSheet.mdx'
    ),
  'src/docs/documentation/datasources-integration/json_api.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-json-api" */ 'src/docs/documentation/datasources-integration/json_api.mdx'
    ),
  'src/docs/documentation/datasources-integration/notifications.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-notifications" */ 'src/docs/documentation/datasources-integration/notifications.mdx'
    ),
  'src/docs/documentation/datasources-integration/s4hana.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-s4hana" */ 'src/docs/documentation/datasources-integration/s4hana.mdx'
    ),
  'src/docs/documentation/datasources-integration/salesforce.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-salesforce" */ 'src/docs/documentation/datasources-integration/salesforce.mdx'
    ),
  'src/docs/documentation/datasources-integration/salesforce_chatter.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-salesforce-chatter" */ 'src/docs/documentation/datasources-integration/salesforce_chatter.mdx'
    ),
  'src/docs/documentation/datasources-integration/salesforce_method.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-salesforce-method" */ 'src/docs/documentation/datasources-integration/salesforce_method.mdx'
    ),
  'src/docs/documentation/datasources-integration/twilio.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-twilio" */ 'src/docs/documentation/datasources-integration/twilio.mdx'
    ),
  'src/docs/documentation/datasources-integration/websocket.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-integration-websocket" */ 'src/docs/documentation/datasources-integration/websocket.mdx'
    ),
  'src/docs/documentation/datasources-utility/clientroutes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-clientroutes" */ 'src/docs/documentation/datasources-utility/clientroutes.mdx'
    ),
  'src/docs/documentation/datasources-utility/credentials.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-credentials" */ 'src/docs/documentation/datasources-utility/credentials.mdx'
    ),
  'src/docs/documentation/datasources-utility/emailer.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-emailer" */ 'src/docs/documentation/datasources-utility/emailer.mdx'
    ),
  'src/docs/documentation/datasources-utility/files.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-files" */ 'src/docs/documentation/datasources-utility/files.mdx'
    ),
  'src/docs/documentation/datasources-utility/javascript.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-javascript" */ 'src/docs/documentation/datasources-utility/javascript.mdx'
    ),
  'src/docs/documentation/datasources-utility/state.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-state" */ 'src/docs/documentation/datasources-utility/state.mdx'
    ),
  'src/docs/documentation/datasources-utility/json.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-json" */ 'src/docs/documentation/datasources-utility/json.mdx'
    ),
  'src/docs/documentation/datasources-utility/user.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-user" */ 'src/docs/documentation/datasources-utility/user.mdx'
    ),
  'src/docs/documentation/datasources-utility/users.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-datasources-utility-users" */ 'src/docs/documentation/datasources-utility/users.mdx'
    ),
  'src/docs/documentation/getting-started/101.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-101" */ 'src/docs/documentation/getting-started/101.mdx'
    ),
  'src/docs/documentation/getting-started/best-practices.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-best-practices" */ 'src/docs/documentation/getting-started/best-practices.mdx'
    ),
  'src/docs/documentation/getting-started/faq.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-faq" */ 'src/docs/documentation/getting-started/faq.mdx'
    ),
  'src/docs/documentation/getting-started/glossary-of-terms.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-glossary-of-terms" */ 'src/docs/documentation/getting-started/glossary-of-terms.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes" */ 'src/docs/documentation/getting-started/release-notes.mdx'
    ),
  'src/docs/documentation/guidelines/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-guidelines-index" */ 'src/docs/documentation/guidelines/index.mdx'
    ),
  'src/docs/documentation/lessons/lesson1.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-lessons-lesson1" */ 'src/docs/documentation/lessons/lesson1.mdx'
    ),
  'src/docs/documentation/lessons/lesson1a.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-lessons-lesson1a" */ 'src/docs/documentation/lessons/lesson1a.mdx'
    ),
  'src/docs/documentation/lessons/lesson1b.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-lessons-lesson1b" */ 'src/docs/documentation/lessons/lesson1b.mdx'
    ),
  'src/docs/documentation/lessons/lesson1c.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-lessons-lesson1c" */ 'src/docs/documentation/lessons/lesson1c.mdx'
    ),
  'src/docs/documentation/lessons/lesson1d.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-lessons-lesson1d" */ 'src/docs/documentation/lessons/lesson1d.mdx'
    ),
  'src/docs/documentation/lessons/lesson1e.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-lessons-lesson1e" */ 'src/docs/documentation/lessons/lesson1e.mdx'
    ),
  'src/docs/documentation/management/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-management-index" */ 'src/docs/documentation/management/index.mdx'
    ),
  'src/docs/documentation/releases/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-releases-index" */ 'src/docs/documentation/releases/index.mdx'
    ),
  'src/docs/documentation/polystack-solutions/client.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-client" */ 'src/docs/documentation/polystack-solutions/client.mdx'
    ),
  'src/docs/documentation/polystack-solutions/crm-for-startups.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-crm-for-startups" */ 'src/docs/documentation/polystack-solutions/crm-for-startups.mdx'
    ),
  'src/docs/documentation/polystack-solutions/dev.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-dev" */ 'src/docs/documentation/polystack-solutions/dev.mdx'
    ),
  'src/docs/documentation/polystack-solutions/digital-banking.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-digital-banking" */ 'src/docs/documentation/polystack-solutions/digital-banking.mdx'
    ),
  'src/docs/documentation/polystack-solutions/employee-portal.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-employee-portal" */ 'src/docs/documentation/polystack-solutions/employee-portal.mdx'
    ),
  'src/docs/documentation/polystack-solutions/ethic.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-ethic" */ 'src/docs/documentation/polystack-solutions/ethic.mdx'
    ),
  'src/docs/documentation/polystack-solutions/family-office.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-family-office" */ 'src/docs/documentation/polystack-solutions/family-office.mdx'
    ),
  'src/docs/documentation/polystack-solutions/investment-research.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-investment-research" */ 'src/docs/documentation/polystack-solutions/investment-research.mdx'
    ),
  'src/docs/documentation/polystack-solutions/project-mngmnt.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-project-mngmnt" */ 'src/docs/documentation/polystack-solutions/project-mngmnt.mdx'
    ),
  'src/docs/documentation/polystack-solutions/residential-lending.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-polystack-solutions-residential-lending" */ 'src/docs/documentation/polystack-solutions/residential-lending.mdx'
    ),
  'src/docs/documentation/security/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-security-index" */ 'src/docs/documentation/security/index.mdx'
    ),
  'src/docs/documentation/user-managment/admins.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-user-managment-admins" */ 'src/docs/documentation/user-managment/admins.mdx'
    ),
  'src/docs/documentation/user-managment/intro.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-user-managment-intro" */ 'src/docs/documentation/user-managment/intro.mdx'
    ),
  'src/docs/documentation/user-managment/users.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-user-managment-users" */ 'src/docs/documentation/user-managment/users.mdx'
    ),
  'src/docs/documentation/utility-apps/board.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-board" */ 'src/docs/documentation/utility-apps/board.mdx'
    ),
  'src/docs/documentation/utility-apps/calendar.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-calendar" */ 'src/docs/documentation/utility-apps/calendar.mdx'
    ),
  'src/docs/documentation/utility-apps/data-mashup.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-data-mashup" */ 'src/docs/documentation/utility-apps/data-mashup.mdx'
    ),
  'src/docs/documentation/utility-apps/easy-minutes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-easy-minutes" */ 'src/docs/documentation/utility-apps/easy-minutes.mdx'
    ),
  'src/docs/documentation/utility-apps/expenses.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-expenses" */ 'src/docs/documentation/utility-apps/expenses.mdx'
    ),
  'src/docs/documentation/utility-apps/google-sheets-dashboard.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-google-sheets-dashboard" */ 'src/docs/documentation/utility-apps/google-sheets-dashboard.mdx'
    ),
  'src/docs/documentation/utility-apps/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-index" */ 'src/docs/documentation/utility-apps/index.mdx'
    ),
  'src/docs/documentation/utility-apps/intro.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-intro" */ 'src/docs/documentation/utility-apps/intro.mdx'
    ),
  'src/docs/documentation/utility-apps/lwcComponent.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-lwc-component" */ 'src/docs/documentation/utility-apps/lwcComponent.mdx'
    ),
  'src/docs/documentation/utility-apps/portals.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-portals" */ 'src/docs/documentation/utility-apps/portals.mdx'
    ),
  'src/docs/documentation/utility-apps/project-mgmt.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-project-mgmt" */ 'src/docs/documentation/utility-apps/project-mgmt.mdx'
    ),
  'src/docs/documentation/utility-apps/public-sign-up.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-public-sign-up" */ 'src/docs/documentation/utility-apps/public-sign-up.mdx'
    ),
  'src/docs/documentation/utility-apps/user-feedback.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-utility-apps-user-feedback" */ 'src/docs/documentation/utility-apps/user-feedback.mdx'
    ),
  'src/docs/documentation/component-reference/Accordion/Accordion.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-accordion-accordion" */ 'src/docs/documentation/component-reference/Accordion/Accordion.mdx'
    ),
  'src/docs/documentation/component-reference/Bar/Bar.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-bar-bar" */ 'src/docs/documentation/component-reference/Bar/Bar.mdx'
    ),
  'src/docs/documentation/component-reference/Board/Board.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-board-board" */ 'src/docs/documentation/component-reference/Board/Board.mdx'
    ),
  'src/docs/documentation/component-reference/Button/Button.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-button-button" */ 'src/docs/documentation/component-reference/Button/Button.mdx'
    ),
  'src/docs/documentation/component-reference/Calendar/Calendar.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-calendar-calendar" */ 'src/docs/documentation/component-reference/Calendar/Calendar.mdx'
    ),
  'src/docs/documentation/component-reference/Carousel/Carousel.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-carousel-carousel" */ 'src/docs/documentation/component-reference/Carousel/Carousel.mdx'
    ),
  'src/docs/documentation/component-reference/Conditional/Conditional.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-conditional-conditional" */ 'src/docs/documentation/component-reference/Conditional/Conditional.mdx'
    ),
  'src/docs/documentation/component-reference/Container/Container.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-container-container" */ 'src/docs/documentation/component-reference/Container/Container.mdx'
    ),
  'src/docs/documentation/component-reference/Email/Email.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-email-email" */ 'src/docs/documentation/component-reference/Email/Email.mdx'
    ),
  'src/docs/documentation/component-reference/FileUpload/FileUpload.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-file-upload-file-upload" */ 'src/docs/documentation/component-reference/FileUpload/FileUpload.mdx'
    ),
  'src/docs/documentation/component-reference/Field/Field.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-field-field" */ 'src/docs/documentation/component-reference/Field/Field.mdx'
    ),
  'src/docs/documentation/component-reference/Form/Form.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-form-form" */ 'src/docs/documentation/component-reference/Form/Form.mdx'
    ),
  'src/docs/documentation/component-reference/HTML/Html.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-html-html" */ 'src/docs/documentation/component-reference/HTML/Html.mdx'
    ),
  'src/docs/documentation/component-reference/Gallery/Gallery.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-gallery-gallery" */ 'src/docs/documentation/component-reference/Gallery/Gallery.mdx'
    ),
  'src/docs/documentation/component-reference/Highlight/Highlight.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-highlight-highlight" */ 'src/docs/documentation/component-reference/Highlight/Highlight.mdx'
    ),
  'src/docs/documentation/component-reference/Icon/Icon.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-icon-icon" */ 'src/docs/documentation/component-reference/Icon/Icon.mdx'
    ),
  'src/docs/documentation/component-reference/Image/Image.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-image-image" */ 'src/docs/documentation/component-reference/Image/Image.mdx'
    ),
  'src/docs/documentation/component-reference/Label/Label.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-label-label" */ 'src/docs/documentation/component-reference/Label/Label.mdx'
    ),
  'src/docs/documentation/component-reference/Line/Line.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-line-line" */ 'src/docs/documentation/component-reference/Line/Line.mdx'
    ),
  'src/docs/documentation/component-reference/LinkWrapper/LinkWrapper.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-link-wrapper-link-wrapper" */ 'src/docs/documentation/component-reference/LinkWrapper/LinkWrapper.mdx'
    ),
  'src/docs/documentation/component-reference/ListView/ListView.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-list-view-list-view" */ 'src/docs/documentation/component-reference/ListView/ListView.mdx'
    ),
  'src/docs/documentation/component-reference/Navigation/Navigation.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-navigation-navigation" */ 'src/docs/documentation/component-reference/Navigation/Navigation.mdx'
    ),
  'src/docs/documentation/component-reference/PageContent/PageContent.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-page-content-page-content" */ 'src/docs/documentation/component-reference/PageContent/PageContent.mdx'
    ),
  'src/docs/documentation/component-reference/Pie/Pie.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-pie-pie" */ 'src/docs/documentation/component-reference/Pie/Pie.mdx'
    ),
  'src/docs/documentation/component-reference/Repeater/Repeater.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-repeater-repeater" */ 'src/docs/documentation/component-reference/Repeater/Repeater.mdx'
    ),
  'src/docs/documentation/component-reference/ScrollSpy/ScrollSpy.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-scroll-spy-scroll-spy" */ 'src/docs/documentation/component-reference/ScrollSpy/ScrollSpy.mdx'
    ),
  'src/docs/documentation/component-reference/Search/Search.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-search-search" */ 'src/docs/documentation/component-reference/Search/Search.mdx'
    ),
  'src/docs/documentation/component-reference/StatusBar/StatusBar.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-status-bar-status-bar" */ 'src/docs/documentation/component-reference/StatusBar/StatusBar.mdx'
    ),
  'src/docs/documentation/component-reference/Tabs/Tabs.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-tabs-tabs" */ 'src/docs/documentation/component-reference/Tabs/Tabs.mdx'
    ),
  'src/docs/documentation/component-reference/Timeline/Timeline.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-timeline-timeline" */ 'src/docs/documentation/component-reference/Timeline/Timeline.mdx'
    ),
  'src/docs/documentation/component-reference/Wizard/Wizard.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-wizard-wizard" */ 'src/docs/documentation/component-reference/Wizard/Wizard.mdx'
    ),
  'src/docs/documentation/component-reference/iFrame/IFrame.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-component-reference-i-frame-i-frame" */ 'src/docs/documentation/component-reference/iFrame/IFrame.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-01-24.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-01-24" */ 'src/docs/documentation/getting-started/release-notes/2020-01-24.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-02-19.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-02-19" */ 'src/docs/documentation/getting-started/release-notes/2020-02-19.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-02-21.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-02-21" */ 'src/docs/documentation/getting-started/release-notes/2020-02-21.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-03-09.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-03-09" */ 'src/docs/documentation/getting-started/release-notes/2020-03-09.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-03-23.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-03-23" */ 'src/docs/documentation/getting-started/release-notes/2020-03-23.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-04-09.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-04-09" */ 'src/docs/documentation/getting-started/release-notes/2020-04-09.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-04-27.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-04-27" */ 'src/docs/documentation/getting-started/release-notes/2020-04-27.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-05-11.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-05-11" */ 'src/docs/documentation/getting-started/release-notes/2020-05-11.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-05-27.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-05-27" */ 'src/docs/documentation/getting-started/release-notes/2020-05-27.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-07-16.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-07-16" */ 'src/docs/documentation/getting-started/release-notes/2020-07-16.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-09-16.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-09-16" */ 'src/docs/documentation/getting-started/release-notes/2020-09-16.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-10-02.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-10-02" */ 'src/docs/documentation/getting-started/release-notes/2020-10-02.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-10-07.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-10-07" */ 'src/docs/documentation/getting-started/release-notes/2020-10-07.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-10-27.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-10-27" */ 'src/docs/documentation/getting-started/release-notes/2020-10-27.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2020-12-02.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2020-12-02" */ 'src/docs/documentation/getting-started/release-notes/2020-12-02.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2021-02-04.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2021-02-04" */ 'src/docs/documentation/getting-started/release-notes/2021-02-04.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2021-06-02.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2021-06-02" */ 'src/docs/documentation/getting-started/release-notes/2021-06-02.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2021-07-06.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2021-07-06" */ 'src/docs/documentation/getting-started/release-notes/2021-07-06.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2021-08-12.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2021-08-12" */ 'src/docs/documentation/getting-started/release-notes/2021-08-12.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2021-10-06.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2021-10-06" */ 'src/docs/documentation/getting-started/release-notes/2021-10-06.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2021-11-15.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2021-11-15" */ 'src/docs/documentation/getting-started/release-notes/2021-11-15.mdx'
    ),
  'src/docs/documentation/getting-started/release-notes/2022-02-02.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-getting-started-release-notes-2022-02-02" */ 'src/docs/documentation/getting-started/release-notes/2022-02-02.mdx'
    ),
}
